import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { STATUSTYPES } from '../Reusable/Status';
import { CURRENCYCODE } from '../Reusable/CurrencyCode';
import { COUNTRYCODE } from '../Reusable/CountryCode';
import { UserApis } from '../../apis/userApi';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'


function EditShipment() {

    const [shipmentData, setShipmentData] = React.useState<any>('');
    const [searchText, setSearchText] = React.useState('');

    const [loader, setLoader] = React.useState<boolean>(false);
    const [loader2, setLoader2] = React.useState<boolean>(false);
    const params: any = useParams();
    let shipmentId: string = params?.id;


    React.useEffect(() => {
        SuperAdminApis.getSingleUserShipment(shipmentId).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setShipmentData(response?.data?.data)
                    setLoader(true)
                    // console?.log(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);


    // let [shipmentDatas, setShipmentDatas] = React.useState<any>({
    //     SenderName: "", SenderEmail: "", SenderCountryCode: '', SenderAdd1: '',
    //     SenderAddCity: '', SenderAddState: '', SenderAddPostcode: '',
    //     SenderTaxID: '', SenderPhone: '',
    //     //Reciever information
    //     ReceiverName: "", ReceiverEmail: "", ReceiverCountryCode: '', ReceiverAdd1: '',
    //     ReceiverAddCity: '', ReceiverAddState: '', ReceiverAddPostcode: '',
    //     ReceiverTaxID: '', ReceiverPhone: '',
    //     //Shipment Information 1
    //     ServiceTypeName: "", NoOfItems: "", HarmonisedCode: '', CubicL: '',
    //     CubicW: '1', CubicH: '1', CubicWeight: '1', DeadWeight: '', CustomValue: '',
    //     CustomCurrencyCode: '', CODAmount: '', CODCurrencyCode: '',
    //     //shipment information 2
    //     GoodsDescription: "", ShipmentTerm: "", Incoterms: '', BusinessType: '', SenderRef1: '',
    //     SenderRef2: '', GoodsOriginCountryCode: '', ReasonExport: '', DeliveryInstructions: '', Notes: '',
    //     //Shipment information 3
    //     CPCCode: "", SKUNumber: "", ATENumber: '', ProductURL: '',
    //     GoodsValue: '', GoodsCurrencyCode: '', DutyValue: '', DutyCurrencyCode: '', EORINumber: '',
    //     FreightCost: '', FreightCostCurrencyCode: '', TotalGSTAmount: '', TotalGSTCurrencyCode: '',
    // });

    const navigate = useNavigate();


    const [shipmentInfos, setShipmentInfos] = React.useState<any>([]);
    const [countryFrom, setCountryFrom] = React.useState<any>([]);
    const [countryTo, setCountryTo] = React.useState<any>([]);
    const [stateFrom, setStateFrom] = React.useState<any>([]);
    const [stateTo, setStateTo] = React.useState<any>([]);
    const [lgaFrom, setLgaFrom] = React.useState<any>([]);
    const [lgaTo, setLgaTo] = React.useState<any>([]);

    const [countryFromData, setCountryFromData] = React.useState<any>('');
    const [countryToData, setCountryToData] = React.useState<any>('');
    const [stateFromData, setStateFromData] = React.useState<any>('');
    const [stateToData, setStateToData] = React.useState<any>('');
    const [lgaFromData, setLgaFromData] = React.useState<any>('');
    const [lgaToData, setLgaToData] = React.useState<any>('');



    React.useEffect(() => {
        SuperAdminApis.getAllCountryFrom('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setCountryFrom(response?.data?.data)
                }
            }
        )

        SuperAdminApis.getAllCountryTo('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setCountryTo(response?.data?.data)
                    // console?.log(response?.data?.data)
                }
            }
        )
    }, []);


    const [totalCost, setTotalCost] = React.useState<any>('');






    const getStateFrom = React.useCallback(
        (id: any) => {
            let data = {
                "country_id": id
            }

            let val = countryFrom?.data?.filter((data: any) => data?.id == id)?.map((d: any) => { return d?.country_code })[0];
            setShipmentData({ ...shipmentData, SenderCountryCode: val, country_from: id });
            // setShipmentData({ ...shipmentData, country_from: id });


            setCountryFromData(id)
            SuperAdminApis.getAllStateFrom('', data).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setStateFrom(response?.data?.data)

                    }
                }
            )

        }, [stateFrom, countryFromData, shipmentData]
    );

    const getStateTo = React.useCallback(
        (id: any) => {
            let data = {
                "country_id": id
            }
            setCountryToData(id)
            SuperAdminApis.getAllStateTo('', data).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setStateTo(response?.data?.data)

                    }
                }
            )

        }, [stateTo, countryToData]
    );

    const getLgaFrom = React.useCallback(
        (id: any) => {
            let data = {
                "state_id": id,
                "country_id": countryFromData
            }
            setStateFromData(id)
            SuperAdminApis.getAllLgaFrom('', data).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setLgaFrom(response?.data?.data)

                    }
                }
            )

        }, [lgaFrom, countryFromData, stateFromData]
    );

    const getLgaTo = React.useCallback(
        (id: any) => {
            let data = {
                "state_id": id,
                "country_id": countryToData
            }
            setStateToData(id)
            SuperAdminApis.getAllLgaTo('', data).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setLgaTo(response?.data?.data)

                    }
                }
            )

        }, [lgaTo, countryToData, stateToData]
    );






    const handleShipmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShipmentData({ ...shipmentData, [e.target.name]: e.target.value });

    }

    React.useEffect(() => {
        const formData = new FormData()
        formData.append('country_from', shipmentData?.country_from)
        formData.append('country_to', shipmentData?.country_to)
        formData.append('state_from', shipmentData?.state_from)
        formData.append('state_to', shipmentData?.state_to)
        formData.append('lga_from', shipmentData?.lga_from == 'empty' ? '' : shipmentData?.lga_from)
        formData.append('lga_to', shipmentData?.lga_to == 'empty' ? '' : shipmentData?.lga_to)
        formData.append('CubicWeight', ((shipmentData?.CubicL * shipmentData?.CubicW * shipmentData?.CubicH) / 6000)?.toString())
        formData.append('Weight', shipmentData?.Weight)
        UserApis.calculateShipment(formData)
            .then((response: any) => {
                if (response?.data) {
                    if (response?.data?.success) {
                        // toast.success(response?.data?.message);
                    }
                    setTotalCost(response?.data?.data)
                }
            }).catch((err: any) => {
                console.log(err);
            })
    }, [shipmentData?.Weight, shipmentData?.CubicL, shipmentData?.CubicW, shipmentData?.CubicH]);



    const updateShipment = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // setLoader(false)
        setLoader2(true);
        let data = setShipmentData({ ...shipmentData, CubicWeight: ((shipmentData?.CubicL * shipmentData?.CubicW * shipmentData?.CubicH) / 6000) });
        SuperAdminApis.updateShipmentData(shipmentData)
            .then((response: any) => {
                if (response?.data) {
                    if (response?.data?.status) {
                        toast.success(response?.data?.message);
                        navigate('/shipments')
                        setLoader2(false)
                    }
                    // console?.log(response?.data)
                }
            }).catch((err: any) => {
                setLoader2(false)
                console.log(err);
            })

    }, [shipmentData, loader2, countryFromData, countryToData, stateFromData, stateToData, lgaFromData, lgaToData, countryFrom, countryTo]);





    return (
        <>
            <Sidebar title="Dashboard" />
            <div className="relative md:ml-64 bg-[#FAFAFA] ">
                <AdminNavbar title="Shipments" />
                <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

                    {loader ?
                        <div className=" lg:grid lg:grid-cols-4 grid-cols-1 gap-5 lg:pt-5">
                            <div className="col-span-4 ">

                                {/* <hr className='my-2' /> */}
                                <div className='flex space-x-2'>
                                    <div className='bg-[#FF3B3017] w-[25px] h-[25px] rounded-full'></div>
                                    <h3 className='text-[16px] font-semibold'>Shipment Information</h3>
                                </div>
                                <div className='bg-white rounded-[10px] py-[20px] px-[35px] mt-[8px]'>
                                    <div>
                                        <form onSubmit={updateShipment}>
                                            <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto ">
                                                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                                    <div className="flex-auto   pt-0">


                                                        <div className=' pb-6  min-h-screen'>
                                                            {/* <!-- Sender Information --> */}
                                                            <div className="md:px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                                                                <div className="flex justify-between">
                                                                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Sender Information</span>
                                                                    {/* <span className="bg-[#E2E8F0] font-[700] text-[#19213D] lg:text-[12px] text-[10px] rounded-[19px] px-[12px] py-[12px]">Use address book</span> */}
                                                                </div>
                                                                <div>

                                                                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                                                                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                                                            <div className="flex-auto   py-10 pt-0">
                                                                                <div className="flex flex-wrap ">

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Name <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Name"
                                                                                                name="SenderName"
                                                                                                defaultValue={shipmentData?.SenderName}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Email  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="email"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Email"
                                                                                                name="SenderEmail"
                                                                                                defaultValue={shipmentData?.SenderEmail}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Country  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <select id="countries" name='SenderCountryCode' onChange={(e: any) => { getStateFrom(e.target.value); }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.SenderCountryCode}</option>
                                                                                                {countryFrom?.data?.map((datas: any, index: any) => (
                                                                                                    <option value={datas?.id}>{datas?.country_name}</option>
                                                                                                )
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-12/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Address  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Name"
                                                                                                name="SenderAdd1"
                                                                                                defaultValue={shipmentData?.SenderAdd1}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                City  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter City"
                                                                                                name="SenderAddCity"
                                                                                                defaultValue={shipmentData?.SenderAddCity}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                State  <span className=' text-red-600'>*</span>
                                                                                            </label>

                                                                                            <select id="countries" name='SenderAddState' onChange={(e: any) => { setShipmentData({ ...shipmentData, SenderAddState: (stateFrom?.data?.filter((data: any) => data?.id == e.target.value)?.map((d: any) => { return d?.state_name })[0]), state_from: e.target.value }); getLgaFrom(e.target.value); }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.SenderAddState}</option>
                                                                                                {stateFrom?.data?.map((datas: any, index: any) => (
                                                                                                    <option value={datas?.id}>{datas?.state_name}</option>
                                                                                                )
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                LGA
                                                                                            </label>
                                                                                            <select id="countries" name='senderLga' onChange={(e: any) => { setShipmentData({ ...shipmentData, senderLga: lgaFrom?.data?.filter((data: any) => data?.id == e.target.value)?.map((d: any) => { return d?.lga_name })[0], lga_from: e.target.value }); setLgaFromData(e.target.value); }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.senderLga}</option>
                                                                                                {lgaFrom?.data?.map((datas: any, index: any) => (
                                                                                                    <option value={datas?.id}>{datas?.lga_name}</option>
                                                                                                )
                                                                                                )}
                                                                                            </select>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Post Code
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Post Code"
                                                                                                name="SenderAddPostcode"
                                                                                                defaultValue={shipmentData?.SenderAddPostcode}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Tax ID
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Tax ID"
                                                                                                name="SenderTaxID"
                                                                                                defaultValue={shipmentData?.SenderTaxID}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Phone Number
                                                                                            </label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Phone Number"
                                                                                                name="SenderPhone"
                                                                                                defaultValue={shipmentData?.SenderPhone}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {/* <!-- Receiver  Information --> */}
                                                            <div className="md:px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                                                                <div className="flex justify-between">
                                                                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Receiver  Information</span>
                                                                    {/* <span className="bg-[#E2E8F0] font-[700] text-[#19213D] lg:text-[12px] text-[10px] rounded-[19px] px-[12px] py-[12px]">Use address book</span> */}
                                                                </div>
                                                                <div>

                                                                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                                                                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                                                            <div className="flex-auto   py-10 pt-0">
                                                                                <div className="flex flex-wrap ">

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Name  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Name"
                                                                                                name="ReceiverName"
                                                                                                defaultValue={shipmentData?.ReceiverName}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Email  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="email"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Email"
                                                                                                name="ReceiverEmail"
                                                                                                defaultValue={shipmentData?.ReceiverEmail}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full ">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Receving Country  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <select id="countries" name='ReceiverCountryCode' onChange={(e: any) => { setShipmentData({ ...shipmentData, ReceiverCountryCode: countryTo?.data?.filter((data: any) => data?.id == e.target.value)?.map((d: any) => { return d?.country_code })[0], country_to: e.target.value }); getStateTo(e.target.value); }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.ReceiverCountryCode}</option>
                                                                                                {countryTo?.data?.map((datas: any, index: any) => (
                                                                                                    <option value={datas?.id}>{datas?.country_name}</option>
                                                                                                )
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-12/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Address  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Name"
                                                                                                name="ReceiverAdd1"
                                                                                                defaultValue={shipmentData?.ReceiverAdd1}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                City  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter City"
                                                                                                name="ReceiverAddCity"
                                                                                                defaultValue={shipmentData?.ReceiverAddCity}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                State  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <select id="countries" name='ReceiverAddState' onChange={(e: any) => { setShipmentData({ ...shipmentData, ReceiverAddState: stateTo?.data?.filter((data: any) => data?.id == e.target.value)?.map((d: any) => { return d?.state_name })[0], state_to: e.target.value }); getLgaTo(e.target.value); }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option disabled selected>{shipmentData?.ReceiverAddState}</option>
                                                                                                {stateTo?.data?.map((datas: any, index: any) => (
                                                                                                    <option value={datas?.id}>{datas?.state_name}</option>
                                                                                                )
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                LGA
                                                                                            </label>
                                                                                            <select id="countries" name='recieverLga' onChange={(e: any) => { setShipmentData({ ...shipmentData, recieverLga: lgaTo?.data?.filter((data: any) => data?.id == e.target.value)?.map((d: any) => { return d?.lga_name })[0], lga_to: e.target.value }); setLgaToData(e.target.value); }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option disabled selected>{shipmentData?.recieverLga}</option>
                                                                                                {lgaTo?.data?.map((datas: any, index: any) => (
                                                                                                    <option value={datas?.id}>{datas?.lga_name}</option>
                                                                                                )
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Post Code
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Post Code"
                                                                                                name="ReceiverAddPostcode"
                                                                                                defaultValue={shipmentData?.ReceiverAddPostcode}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Tax ID
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Post Code"
                                                                                                name="ReceiverTaxID"
                                                                                                defaultValue={shipmentData?.ReceiverTaxID}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Phone Number
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Enter Phone Number"
                                                                                                name="ReceiverPhone"
                                                                                                defaultValue={shipmentData?.ReceiverPhone}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>




                                                        <div className='  pb-6'>
                                                            <div className="md:px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                                                                <div className="flex justify-between">
                                                                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Shipments Information</span>
                                                                </div>
                                                                <div>

                                                                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                                                                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                                                            <div className="flex-auto   py-10 pt-0">
                                                                                <div className="flex flex-wrap ">

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Service  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <select id="countries" name='ServiceTypeName' defaultValue={shipmentData?.ServiceTypeName} onChange={(e: any) => handleShipmentChange(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.ServiceTypeName}</option>
                                                                                                <option value="DOME">Domestic Express</option>
                                                                                                <option value="ED">Express Documents</option>
                                                                                                <option value="EN">Express Non Documents</option>
                                                                                                <option value="DOMS">Domestic Saver</option>
                                                                                                <option value="SKYSAV">SkySaver</option>
                                                                                                <option value="AF">Air Freight</option>
                                                                                                <option value="Cargo">Cargo</option>
                                                                                                <option value="DG">Dangerous Goods</option>
                                                                                                <option value="HV">Heavy Shipment</option>
                                                                                            </select>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                No of Items  <span className=' text-red-600'>*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder="Item Number"
                                                                                                name="NoOfItems"
                                                                                                defaultValue={shipmentData?.NoOfItems}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Harmonized Code
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder=""
                                                                                                name="HarmonisedCode"
                                                                                                defaultValue={shipmentData?.HarmonisedCode}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Cubic Measurement (L x W x H) (CM)
                                                                                            </label>
                                                                                            <div className='flex space-x-3'>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                    placeholder="L"
                                                                                                    name="CubicL"
                                                                                                    defaultValue={shipmentData?.CubicL}
                                                                                                    onChange={handleShipmentChange}

                                                                                                />
                                                                                                <span className='py-[3px]'>x</span>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-1 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                    placeholder="W"
                                                                                                    name="CubicW"
                                                                                                    defaultValue={shipmentData?.CubicW}
                                                                                                    onChange={handleShipmentChange}

                                                                                                />
                                                                                                <span className='py-[3px]'>x</span>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-1 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                    placeholder="H"
                                                                                                    name="CubicH"
                                                                                                    defaultValue={shipmentData?.CubicH}
                                                                                                    onChange={handleShipmentChange}

                                                                                                />

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Volume Weight (KG)
                                                                                            </label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder=""
                                                                                                name="CubicWeight"
                                                                                                disabled
                                                                                                // defaultValue={shipmentData?.DeadWeight}
                                                                                                value={((shipmentData?.CubicL * shipmentData?.CubicW * shipmentData?.CubicH) / 6000)}
                                                                                                onChange={(e) => { handleShipmentChange(e); }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                            Gross Weight (KG)
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                            placeholder="Weight"
                                                                                            name="Weight"
                                                                                            defaultValue={shipmentData?.Weight}
                                                                                            onChange={handleShipmentChange}

                                                                                        />
                                                                                    </div>

                                                                                    <div className="w-full lg:w-12/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Description</label>
                                                                                            <textarea id="message" onChange={(e: any) => handleShipmentChange(e)} defaultValue={shipmentData?.GoodsDescription} name='GoodsDescription' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write your thoughts here..."></textarea>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                    <div className="relative w-full">
                                                                                        <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                            Freight Cost 
                                                                                        </label>
                                                                                        <div className='flex'>
                                                                                            <div className=' basis-2/3'>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                    placeholder=""
                                                                                                    name="FreightCost"
                                                                                                    disabled
                                                                                                    defaultValue={totalCost}
                                                                                                    onChange={handleShipmentChange}

                                                                                                />
                                                                                            </div>
                                                                                            <div className=' basis-1/3'>
                                                                                                <select disabled id="countries" defaultValue={shipmentData?.Notes} onChange={(e: any) => handleShipmentChange(e)} name='FreightCostCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                    <option selected>{shipmentData?.FreightCostCurrencyCode}</option>
                                                                                                    {
                                                                                                        CURRENCYCODE?.map((datas: any, index: any) => (
                                                                                                            <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                                                                                        )
                                                                                                        )}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}
                                                                                    {/* </div> */}

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full ">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Customs Value
                                                                                            </label>
                                                                                            <div className='flex'>
                                                                                                <div className=' basis-2/3'>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                        placeholder=""
                                                                                                        name="CustomValue"
                                                                                                        defaultValue={shipmentData?.CustomValue}
                                                                                                        onChange={handleShipmentChange}

                                                                                                    />
                                                                                                </div>
                                                                                                <div className=' basis-1/3'>
                                                                                                    <select id="countries" defaultValue={shipmentData?.ServiceTypeName} onChange={(e: any) => handleShipmentChange(e)} name='CustomCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                        <option selected>{shipmentData?.CustomCurrencyCode}</option>
                                                                                                        {
                                                                                                            CURRENCYCODE?.map((datas: any, index: any) => (
                                                                                                                <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                                                                                            )
                                                                                                            )}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                COD Amount
                                                                                            </label>
                                                                                            <div className='flex'>
                                                                                                <div className=' basis-2/3'>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                        placeholder=""
                                                                                                        name="CODAmount"
                                                                                                        defaultValue={shipmentData?.CODAmount}
                                                                                                        onChange={handleShipmentChange}

                                                                                                    />
                                                                                                </div>
                                                                                                <div className=' basis-1/3'>
                                                                                                    <select id="countries" onChange={(e: any) => handleShipmentChange(e)} defaultValue={shipmentData?.ServiceTypeName} name='CODCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                        <option selected>{shipmentData?.CODCurrencyCode}</option>
                                                                                                        {
                                                                                                            CURRENCYCODE?.map((datas: any, index: any) => (
                                                                                                                <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                                                                                            )
                                                                                                            )}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Request Pickup </label>
                                                                                            <select id="countries" onChange={(e: any) => handleShipmentChange(e)} name='BookPickUP' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.BookPickUP == '0'? 'false':shipmentData?.BookPickUP}</option>
                                                                                                <option value="true">Yes</option>
                                                                                                <option value="false">No</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {/* <!-- Shipment Information --> */}
                                                            <div className="md:px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                                                                <div className="flex justify-between">
                                                                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Shipment  Information</span>
                                                                </div>
                                                                <div>

                                                                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                                                                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                                                            <div className="flex-auto   py-10 pt-0">
                                                                                <div className="flex flex-wrap ">

                                                                                    <div className="w-full lg:w-12/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Description</label>
                                                                                            <textarea id="message" onChange={(e: any) => handleShipmentChange(e)} defaultValue={shipmentData?.GoodsDescription} name='GoodsDescription' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write your thoughts here..."></textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Shipment Terms</label>
                                                                                            <select id="countries" defaultValue={shipmentData?.ShipmentTerm} onChange={(e: any) => handleShipmentChange(e)} name='ShipmentTerm' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.ShipmentTerm}</option>
                                                                                                <option value="DDU">DDU</option>
                                                                                                <option value="DDP">DDP</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Incoterms</label>
                                                                                            <select id="countries" defaultValue={shipmentData?.Incoterms} onChange={(e: any) => handleShipmentChange(e)} name='Incoterms' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.Incoterms}</option>
                                                                                                <option value="CIF">CIF</option>
                                                                                                <option value="CFR">CFR</option>
                                                                                                <option value="FOB">FOB</option>
                                                                                                <option value="DE">DE</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Type of Business</label>
                                                                                            <select id="countries" defaultValue={shipmentData?.BusinessType} onChange={(e: any) => handleShipmentChange(e)} name='BusinessType' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.BusinessType}</option>
                                                                                                <option value="B2C">B2C</option>
                                                                                                <option value="B2B">B2B</option>
                                                                                                <option value="C2C">C2C</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Customer Ref #1
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                name="SenderRef1"
                                                                                                defaultValue={shipmentData?.SenderRef1}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                            <div className="flex items-center mb-4 mt-2">
                                                                                                <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                                                                                <label htmlFor="default-checkbox" className="ml-2 text-[12px] font-medium text-gray-900">Use receiver's customer ref.</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Customer Ref #2
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                name="SenderRef2"
                                                                                                defaultValue={shipmentData?.SenderRef2}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="countries" className="block mb-2 text-sm font-semibold text-[#19213D]">Origin of goods</label>
                                                                                            <select id="countries" defaultValue={shipmentData?.GoodsOriginCountryCode} onChange={(e: any) => handleShipmentChange(e)} name='GoodsOriginCountryCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                <option selected>{shipmentData?.GoodsOriginCountryCode}</option>
                                                                                                {
                                                                                                    COUNTRYCODE?.map((datas: any, index: any) => (
                                                                                                        <option value={datas?.CountryCode}>{datas?.CountrynName}</option>
                                                                                                    )
                                                                                                    )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-12/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Reason for Export</label>
                                                                                            <textarea id="message" defaultValue={shipmentData?.ReasonExport} onChange={(e: any) => handleShipmentChange(e)} name='ReasonExport' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..."></textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-12/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Delivery Notes</label>
                                                                                            <textarea id="message" defaultValue={shipmentData?.DeliveryInstructions} onChange={(e: any) => handleShipmentChange(e)} name='DeliveryInstructions' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..."></textarea>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-full lg:w-12/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Notes</label>
                                                                                            <textarea id="message" defaultValue={shipmentData?.Notes} onChange={(e: any) => handleShipmentChange(e)} name='Notes' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..."></textarea>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="md:px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                                                                <div className="flex justify-between">
                                                                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Shipments Information</span>
                                                                </div>
                                                                <div>

                                                                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                                                                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                                                            <div className="flex-auto   py-10 pt-0">
                                                                                <div className="flex flex-wrap ">

                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                CPC Code
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                name="CPCCode"
                                                                                                defaultValue={shipmentData?.CPCCode}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                SKU Number
                                                                                            </label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                name="SKUNumber"
                                                                                                defaultValue={shipmentData?.SKUNumber}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                ATENumber
                                                                                            </label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                name="ATENumber"
                                                                                                defaultValue={shipmentData?.ATENumber}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Product URL
                                                                                            </label>

                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder=""
                                                                                                name="ProductURL"
                                                                                                defaultValue={shipmentData?.ProductURL}
                                                                                                onChange={handleShipmentChange}

                                                                                            />

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Total Cost of Goods
                                                                                            </label>
                                                                                            <div className='flex'>
                                                                                                <div className=' basis-2/3'>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                        placeholder=""
                                                                                                        name="GoodsValue"
                                                                                                        defaultValue={shipmentData?.GoodsValue}
                                                                                                        onChange={handleShipmentChange}

                                                                                                    />
                                                                                                </div>
                                                                                                <div className=' basis-1/3'>
                                                                                                    <select id="countries" defaultValue={shipmentData?.Notes} onChange={(e: any) => handleShipmentChange(e)} name='GoodsCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                        <option selected>{shipmentData?.GoodsCurrencyCode}</option>
                                                                                                        {
                                                                                                            CURRENCYCODE?.map((datas: any, index: any) => (
                                                                                                                <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                                                                                            )
                                                                                                            )}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full mb-5">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Duties
                                                                                            </label>
                                                                                            <div className='flex'>
                                                                                                <div className=' basis-2/3'>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                        placeholder=""
                                                                                                        name="DutyValue"
                                                                                                        defaultValue={shipmentData?.DutyValue}
                                                                                                        onChange={handleShipmentChange}

                                                                                                    />
                                                                                                </div>
                                                                                                <div className=' basis-1/3'>
                                                                                                    <select id="countries" defaultValue={shipmentData?.Notes} onChange={(e: any) => handleShipmentChange(e)} name='DutyCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                        <option selected>{shipmentData?.DutyCurrencyCode}</option>
                                                                                                        {
                                                                                                            CURRENCYCODE?.map((datas: any, index: any) => (
                                                                                                                <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                                                                                            )
                                                                                                            )}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full ">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                EQRI
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                placeholder=""
                                                                                                name="EORINumber"
                                                                                                defaultValue={shipmentData?.EORINumber}
                                                                                                onChange={handleShipmentChange}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Freight Cost
                                                                                            </label>
                                                                                            <div className='flex'>
                                                                                                <div className=' basis-2/3'>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                        placeholder=""
                                                                                                        name="FreightCost"
                                                                                                        disabled
                                                                                                        defaultValue={totalCost}
                                                                                                        onChange={handleShipmentChange}

                                                                                                    />
                                                                                                </div>
                                                                                                <div className=' basis-1/3'>
                                                                                                    <select disabled id="countries" defaultValue={shipmentData?.Notes} onChange={(e: any) => handleShipmentChange(e)} name='FreightCostCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                        <option selected>{shipmentData?.FreightCostCurrencyCode}</option>
                                                                                                        {
                                                                                                            CURRENCYCODE?.map((datas: any, index: any) => (
                                                                                                                <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                                                                                            )
                                                                                                            )}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                        <div className="relative w-full">
                                                                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                                Total GST paid (if any)
                                                                                            </label>
                                                                                            <div className='flex'>
                                                                                                <div className=' basis-2/3'>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-l-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                                        placeholder=""
                                                                                                        name="TotalGSTAmount"
                                                                                                        defaultValue={shipmentData?.TotalGSTAmount}
                                                                                                        onChange={handleShipmentChange}

                                                                                                    />
                                                                                                </div>
                                                                                                <div className=' basis-1/3'>
                                                                                                    <select id="countries" defaultValue={shipmentData?.Notes} onChange={(e: any) => handleShipmentChange(e)} name='TotalGSTCurrencyCode' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                                                                        <option selected>{shipmentData?.TotalGSTCurrencyCode}</option>
                                                                                                        {
                                                                                                            CURRENCYCODE?.map((datas: any, index: any) => (
                                                                                                                <option value={datas?.CurrencyCode}>{datas?.CurrencyCode}</option>
                                                                                                            )
                                                                                                            )}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div className="md:px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                                                                <div className="flex justify-between">
                                                                    <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Additional Costs (For Admin Only)</span>
                                                                </div>
                                                                <div>

                                                                    <div>
                                                                        <div className="w-full lg:w-12/12 lg:pr-4">
                                                                            <div className="relative w-full mb-5">
                                                                                <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Cost Breakdown </label>
                                                                                <textarea id="message" disabled defaultValue={shipmentData?.other_costs} onChange={(e: any) => handleShipmentChange(e)} name='other_costs' rows={4} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..."></textarea>
                                                                            </div>
                                                                        </div>

                                                                        <div className="w-full lg:w-6/12 lg:pr-4">
                                                                            <div className="relative w-full mb-5">
                                                                                <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Total </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                    placeholder=""
                                                                                    disabled
                                                                                    name="other_costs_total"
                                                                                    defaultValue={shipmentData?.other_costs_total}
                                                                                    onChange={handleShipmentChange}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {/* <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                                                            <div className="flex justify-between">
                                                                <span className="font-[700] text-[#19213D] lg:text-[14px] text-[12px] bg-[#EBEFF6] pl-[12px] py-[12px] pr-[40px] rounded-[13px]">Shipment Options</span>
                                                            </div>
                                                            <div>

                                                                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                                                                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                                                        <div className="flex-auto   py-10 pt-0">
                                                                            <div className="flex flex-wrap ">

                                                                                <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                    <div className="relative w-full mb-5">
                                                                                        <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                            Run ID
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                            placeholder=""
                                                                                            name="f_name"

                                                                                        />

                                                                                        <div className="flex items-center mb-4 mt-2">
                                                                                            <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                                                                            <label htmlFor="default-checkbox" className="ml-2 text-[12px] font-medium text-gray-900">Assign to Run ID</label>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-full lg:w-4/12 lg:pr-4">
                                                                                    <div className="relative w-full mb-5">
                                                                                        <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                                                            Despatch Date
                                                                                        </label>
                                                                                        <input
                                                                                            type="date"
                                                                                            className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                                            placeholder=""
                                                                                            name="f_name"

                                                                                        />
                                                                                        <div className='flex justify-between'>
                                                                                            <div className="flex items-center mb-4 mt-2">
                                                                                                <input type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                                                                                <label className="ml-2 text-[12px] font-medium text-gray-900">Ship After Saving  Record</label>
                                                                                            </div>
                                                                                            <div className="flex items-center mb-4 mt-2">
                                                                                                <input type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" />
                                                                                                <label className="ml-2 text-[12px] font-medium text-gray-900">Book a Pick Up </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div> */}

                                                            <div className='flex justify-end mt-6'>
                                                                <div className='flex space-x-10'>
                                                                    {/* <div>
                                                                    <button type='button' className='bg-[#B3B3BF] rounded-[40px] py-[5px] px-[20px] text-white'>{`<`} &nbsp;Previous</button>
                                                                </div> */}
                                                                    <div>
                                                                        <button
                                                                            // onClick={() => setShowScreen(2)}
                                                                            type='submit'
                                                                            disabled={loader2}
                                                                            className='bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'>

                                                                            <span> Update Shipment</span>

                                                                            <span>
                                                                                {loader2 ?
                                                                                    <Oval
                                                                                        visible={true}
                                                                                        height="20"
                                                                                        width="20"
                                                                                        color="#f9adad"
                                                                                        secondaryColor="#FF3B300D"
                                                                                        ariaLabel="oval-loading"
                                                                                        wrapperStyle={{}}
                                                                                        wrapperClass=""
                                                                                    />
                                                                                    :
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="white" d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z" /></g></svg>
                                                                                }
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>







                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <div >
                            <div className='bg-white'>Notes</div>

                        </div> */}
                        </div>

                        :
                        <div className='flex justify-center my-3 mt-[50vh] pb-10'>
                            <Oval
                                visible={true}
                                height="80"
                                width="80"
                                color="#E04343"
                                secondaryColor="#FF3B300D"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    }





                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default EditShipment