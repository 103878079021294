import React from 'react'
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { AxiosResponse } from 'axios';
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';

import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import AdminSidebar from '../Sidebar/AdminSidebar';


function Dashboard() {

  const userLoginData = useSelector((state: any) => state.data.login.value);


  const WelcomeMessages = [
    "Welcome back, {name}!<br />We hope your day is going smoothly. Ready to tackle today’s tasks?",
    "Hello {name}!<br />We trust you had a great morning. Let’s make today productive and efficient!",
    "Good to see you back, {name}!<br />How’s your day shaping up? Let’s dive into today’s work.",
    "Welcome back, {name}!<br />Hope your day is off to a good start. Let’s get to work and achieve our goals!",
    "Hi {name}!<br />We hope everything is going well. Let’s have another great day of productivity!",
    "Welcome back, {name}!<br />Let’s make today another successful day. How are you feeling?",
    "Good to see you, {name}!<br />We’re ready to hit the ground running. Hope you’re having a fantastic day!",
    "Hello {name}!<br />Time to conquer today’s challenges. Hope your day has been positive so far!",
    "Welcome back, {name}!<br />Let’s make today as efficient and smooth as possible. How’s everything with you?",
    "Hi {name}!<br />It’s great to have you back. Here’s to a productive day ahead. How’s your day treating you?",
    "Welcome back, {name}.<br />We trust your day is progressing well. Let’s get started on our goals for today.",
    "Hello {name}!<br />We hope you’re having a pleasant day. Let’s focus on our tasks and make today a success.",
    "Good to have you back, {name}.<br />We hope your day is going well. Let’s aim for a productive session today.",
    "Welcome back, {name}.<br />We hope your day is treating you well. Let’s tackle today’s challenges with enthusiasm.",
    "Hi {name}!<br />We hope you’re doing well today. Let’s work together to achieve our objectives efficiently."
];
// Function to get a random welcome message
const getRandomWelcomeMessage = (name:any) => {
    const randomIndex = Math.floor(Math.random() * WelcomeMessages.length);
    const message = WelcomeMessages[randomIndex];
    return message.replace(/{name}/g, name);
};



  return (
    <>
      <AdminSidebar title="Dashboard" />
      <div className="relative md:ml-64 ">
        <AdminNavbar title="Dashboard" />
        <div className=' flex justify-center text-center pt-[30vh]'>
        <span className='md:text-[42px] text-[20px] font-bold text-[#E04343] md:px-10 px-3' dangerouslySetInnerHTML={{ __html: getRandomWelcomeMessage(userLoginData?.data?.first_name) }} /> 

        </div>






      </div>
    </>
  )
}

export default Dashboard